import type { MenuItem, Settings } from "~/types/common";
import { Show } from "solid-js";
import MenuFooter from "~/components/shared/MenuFooter";
import { encodeStr, openEncodedUrl, urlRs } from "~/utils/url";
import { hasNoEscape } from "~/utils/no_escape";
import { ContactPhoneBtn } from "~/components/shared/ContactPhone";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import LogoAltarea from "~/img/logos/altarea.svg";
import LogoSourdlineWhite from "~/img/logos/sourdline-white-2023.svg";
import LogoFacebook from "~/img/logos/facebook.svg";
import LogoYoutube from "~/img/logos/youtube.svg";
import LogoInstagram from "~/img/logos/instagram.svg";
import LogoLinkedin from "~/img/logos/linkedin.svg";
import ToolTipHTML from "~/components/shared/ToolTipHTML";

import "./Footer.css";

type FooterProps = {
  menuItems: MenuItem[];
  settings: Settings;
};

export default function Footer(props: FooterProps) {
  let sourdLineAnchor: HTMLAnchorElement;
  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <footer
        class="footer-part"
        role="contentinfo"
        data-ga-zone="footer"
        data-test="footer"
      >
        <div class="baseline">
          <div class="altarea">
            <span>Une marque Altarea</span>
          </div>
          <div class="logo-altarea">
            <LogoAltarea />
          </div>
        </div>
        <div class="footer-2023">
          <div class="content-part with-background">
            <div
              class="flex-row"
              classList={{
                "with-escda": settingsContext.escda_enabled && !hasNoEscape(),
              }}
            >
              <ContactPhoneBtn eventZone="footer" />
              <Show when={settingsContext.escda_enabled && !hasNoEscape()}>
                <a
                  href={urlRs(
                    "journeys",
                    "/cogedim-pour-vous/pourquoi-choisir-cogedim/un-accompagnement-sur-mesure.html",
                  )}
                  class="escda"
                  data-test="escda-logo"
                >
                  <img
                    loading="lazy"
                    src="/images/logo-escda-2025.svg"
                    width="93"
                    height="100"
                    alt="Élu Service Client de l'Année 2025 dans la catégorie Promotion immobilière"
                  />
                </a>
              </Show>
              <div class="footer-content">
                <Show
                  fallback={
                    <ul data-test="menu" class="menu-footer">
                      <li class="grer-les-cookies">
                        <span
                          class="lnk"
                          onClick={() => {
                            // @ts-expect-error Didomi is injected at runtime
                            Didomi.preferences.show();
                          }}
                        >
                          Gérer les cookies
                        </span>
                      </li>
                      <li class="mentions-lgales">
                        <a href="/mentions-legales.html">Mentions légales</a>
                      </li>
                      <li class="donnes-personnelles">
                        <span
                          class="lnk"
                          onClick={() =>
                            openEncodedUrl(
                              encodeStr("/donnees-personnelles.html"),
                            )
                          }
                        >
                          Données Personnelles
                        </span>
                      </li>
                    </ul>
                  }
                  when={!hasNoEscape()}
                >
                  <MenuFooter
                    name="footer"
                    items={props.menuItems}
                    class="menu-footer"
                  />
                </Show>
              </div>
            </div>
            <Show when={!hasNoEscape()}>
              <div class="flex-bottom">
                <Show when={props.settings.sourdline_enabled}>
                  <a
                    ref={sourdLineAnchor!}
                    href="#"
                    onClick={() => {
                      sourdLineAnchor.href = atob(
                        "aHR0cHM6Ly9jb2dlZGltLnNvdXJkbGluZS5jb20v",
                      );
                    }}
                    target="_blank"
                    class="sourdline"
                    data-test="sourdline"
                    title="Espace sourd"
                  >
                    <LogoSourdlineWhite />
                  </a>
                </Show>
                <div class="social">
                  <a
                    href="https://fr-fr.facebook.com/cogedim/"
                    target="_blank"
                    class="facebook-icon"
                    data-test="facebook"
                  >
                    <LogoFacebook />
                    Facebook
                  </a>
                  <a
                    href="https://www.youtube.com/user/CogedimLogement"
                    target="_blank"
                    class="youtube-icon"
                    data-test="youtube"
                  >
                    <LogoYoutube />
                    Youtube
                  </a>
                  <a
                    href="https://www.instagram.com/cogedim/"
                    target="_blank"
                    class="instagram-icon"
                    data-test="instagram"
                  >
                    <LogoInstagram />
                    Instagram
                  </a>
                  <a
                    href="https://fr.linkedin.com/company/cogedim"
                    target="_blank"
                    class="linkedin-icon"
                    data-test="linkedin"
                  >
                    <LogoLinkedin />
                    LinkedIn
                  </a>
                </div>
              </div>
            </Show>
          </div>
          <div class="content-part" data-test="legals">
            <Show when={props.settings.import_last_date}>
              <p class="last-update" data-test="last-update">
                Date de mise à jour : {props.settings.import_last_date}
              </p>
            </Show>
            <p class="legals" data-test="legals-main-invest">
              Comme tout investissement, investir en LMNP peut présenter des
              risques. L'équilibre économique de l'opération de défiscalisation
              dépend principalement du marché locatif, des charges liées à la
              détention d'un bien en vue de sa location et de la fiscalité
              immobilière.
            </p>
            <div class="legals without-padding">
              <ToolTipHTML
                width={280}
                forceLeft
                tip={
                  <>
                    <p>Investir en LMNP peut présenter des risques.</p>
                    <ul>
                      <li>
                        Le risque de bénéficier d'un avantage fiscal réel minoré
                        en raison d'un prix d'acquisition dans le neuf supérieur
                        au prix moyen du marché
                      </li>
                      <li>
                        Le risque de réaliser une moins-value à la revente du
                        logement
                      </li>
                      <li>
                        Le risque de subir un non renouvellement de bail avec le
                        gestionnaire au terme du bail initial entrainant
                        l’absence de loyers jusqu’à l’arrivée d’un nouvel
                        exploitant
                      </li>
                      <li>
                        Le risque d'une rentabilité dégradée en cas de
                        changement de gestionnaire de la résidence service au
                        cours ou au terme du bail initial
                      </li>
                    </ul>
                  </>
                }
              >
                <span class="underline">En savoir plus.</span>
              </ToolTipHTML>
            </div>

            <div id="legals-offer-receiver" />

            <Show when={settingsContext.escda_enabled && !hasNoEscape()}>
              <p class="legals" data-test="escda-legals">
                *Étude BVA Xsight – Viséo CI – Plus d’infos sur{" "}
                <a href="https://www.escda.fr" target="_blank">
                  escda.fr
                </a>
              </p>
            </Show>
          </div>
        </div>
      </footer>
    </>
  );
}
